import * as React from 'react';

import logoDark from '../assets/images/logo-dark.svg';
import logoLight from '../assets/images/logo-light.svg';
import {Link, useLocation} from "react-router-dom";
import {THEME} from '../constants';

export default function Logo({disableLink = false, theme = THEME.LIGHT}) {
    const location = useLocation();

    const image = <img id={'logo'} src={theme === THEME.LIGHT ? logoDark : logoLight} alt={'Markus Foundation'}/>;

    if (location.pathname === '/' || disableLink) {
        return image;
    }

    return (<Link to={'/'}>{image}</Link>)
}
