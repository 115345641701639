import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {useRef} from "react";
import useOnScreen from "../../../utils/useOnScreen";
import {URLs} from "../../../constants";
import Button from "@mui/material/Button";

export default function Abroad() {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (<Box id={'abroad'}>
        <Box ref={ref} className={isVisible ? 'fadeInDown' : ''}>
            <Typography variant={'h2'}>ЯКЩО ВИ З ІНШОЇ КРАЇНИ, ТО ЧОМУ ВАЖЛИВО НАС ПІДТРИМАТИ</Typography>

            <Typography className={'text'}>
                Подавати набої можна й на відстані. Навіть якщо ви знаходитесь за тисячі кілометрів від України. Не у
                всіх є можливість воювати на передовій, це зрозуміло. Проте за кожним солдатом 47 бригади –
                підтримка десятків і сотень людей, зокрема з-за кордону.
            </Typography>

            <Box style={{marginTop: '2rem', justifyContent: 'flex-end', display: 'flex'}}>
                <Button className={'btn btn-gold'} component={Link} to={URLs.REQUISITES}>Зробити внесок</Button>
            </Box>
        </Box>
    </Box>)
}
