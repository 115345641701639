import {URLs} from "./constants";

const MetaTags = {
    'home': {
        name: 'Головна',
        image: 'markus-foundation.jpg',
        title: 'Markus Foundation – Благодійний фонд підтримки 47 бригади',
        description: 'Офіційний сайт благодійного фонду Markus Foundation. Благодійний фонд підтримки 47 бригади. Переказати кошти для підтримки 47 бригади Збройних Сил України.',
        ogTitle: 'Markus Foundation – це благодійний фонд, який працює на потреби 47 бригади',
        ogDescription: 'Переказати кошти для підтримки 47 бригади Збройних Сил України.'
    },
    [URLs.FOUNDATION]: {
        name: 'Фонд',
        image: 'blahodiynyy-fond-markus-foundation.jpg',
        title: 'Про фонд Markus Foundation – Благодійний фонд підтримки 47 бригади',
        description: 'Про фонд Markus Foundation. Благодійний фонд підтримки 47 бригади. Основні напрями роботи фонду Markus Foundation: Матеріально-технічне забезпечення 47 бригади, навчання та тренування із залученням інструкторів провідних армій світу. Підтримка поранених та сімей загиблих героїв. Офіційний сайт благодійного фонду Markus Foundation.',
        ogTitle: 'Про фонд Markus Foundation ',
        ogDescription: 'Основні напрями роботи фонду Markus Foundation: Матеріально-технічне забезпечення 47 бригади, навчання та тренування із залученням інструкторів провідних армій світу. Підтримка поранених та сімей загиблих героїв.'
    },
    [URLs.BRIGADE]: {
        name: 'бригада',
        image: '47-shturmovyy-polk.jpg',
        title: '47 бригада – Благодійний фонд Markus Foundation',
        description: 'Про 47 бригаду. 47 бригада Збройних Сил України (ЗСУ). 47-й Підрозділ ЗСУ. Офіційний сайт благодійного фонду Markus Foundation.  ',
        ogTitle: '47 бригада',
        ogDescription: 'Історія створення та розвитку 47-го бригади. Переказати кошти для підтримки 47 бригади Збройних Сил України. '
    },
    [URLs.REQUISITES]: {
        name: 'Реквізити',
        image: 'rekvizyty-blahodiynoho-fondu-markus-foundation.jpg',
        title: 'Реквізити благодійного фонду Markus Foundation – Благодійний фонд підтримки 47 бригади',
        description: 'Реквізити благодійного фонду Markus Foundation. Переказати кошти для підтримки 47 бригади Збройних Сил України. Офіційний сайт благодійного фонду Markus Foundation.  ',
        ogTitle: 'Реквізити благодійного фонду Markus Foundation',
        ogDescription: 'Переказати кошти для підтримки 47 бригади Збройних Сил України. '
    },
    [URLs.REPORTS]: {
        name: 'Закупівлі',
        image: 'zakupivli-blahodiynoho-fondu-markus-foundation.jpg',
        title: 'Закупівлі благодійного фонду Markus Foundation – Благодійний фонд підтримки 47 бригади',
        description: 'Закупівлі благодійного фонду Markus Foundation. Благодійний фонд підтримки 47 бригади. Офіційний сайт благодійного фонду Markus Foundation.',
        ogTitle: 'Закупівлі благодійного фонду Markus Foundation',
        ogDescription: 'Найбільш вагомі придбання, зроблені фондом. Дізнайтесь, яку саме користь приносить ваша щира допомога. Разом до перемоги!'
    },
    [URLs.SITEMAP]: {
        name: 'Мапа сайту',
        image: 'mapa-saytu-blahodiynoho-fondu-markus-foundation.jpg',
        title: 'Мапа сайту благодійного фонду Markus Foundation – Благодійний фонд підтримки 47 бригади',
        description: 'Мапа сайту благодійного фонду Markus Foundation. Благодійний фонд підтримки 47 бригади. Переказати кошти для підтримки 47 бригади Збройних Сил України. Офіційний сайт благодійного фонду Markus Foundation.',
        ogTitle: 'Мапа сайту ',
        ogDescription: 'Мапа сайту благодійного фонду Markus Foundation.Переказати кошти для підтримки 47-го бригади Збройних Сил України. '
    },
};

export function getPageMeta(page: URLs) {
    if (page === URLs.MAIN || !page) {
        page = URLs.HOME;
    }

    return (MetaTags as any)[page];
}
