import {Box, Container, Link, Typography} from "@mui/material";
import about6 from "../../../assets/images/about-6.jpg";
import {useRef} from "react";
import useOnScreen from "../../../utils/useOnScreen";


export default function ForCivilians() {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <Container id={'brigade-for-civilians'} className={'template image-right'}>
            <Box className={'text-wrapper' + (isVisible ? ' fadeInLeft' : '')}>
                <Typography variant={'h2'}>
                    Для цивільних: ІЗ ВАШОЮ ПІДТРИМКОЮ МИ ЩЕ СИЛЬНІШІ</Typography>
                <Typography variant={'body1'} className={'text'}>
                    Допомогти нам відстояти незалежність до снаги кожному, навіть, якщо немає можливості особисто
                    приєднатися до 47 бригади. Запрошуємо долучитися до захисту України у вигляді реальних дій з
                    підтримки воїнів. Сьогодні ми закладаємо основу для розбудови нашого майбутнього.
                    <br/>
                    <br/>
                    Підписуйтесь на чат-бот <Link href={'https://t.me/markus_foundation_bot'}
                                                  target={'_blank'}>https://t.me/markus_foundation_bot</Link>&nbsp;
                    та сторінку у <Link href={'https://www.facebook.com/Markus-Foundation-102960955818665'}
                                        target={'_blank'}>Facebook</Link> та <Link
                    href={'https://www.instagram.com/markus.foundation/'}>Instagram</Link>: стежте за новинами,
                    приєднуйтесь до активностей, робіть благодійні внески до фонду Markus Foundation. Ми здатні на
                    великі звершення лише разом! Особливо разом!
                </Typography>
            </Box>

            <Box className={'image-wrapper' + (isVisible ? ' fadeInRight' : '')} ref={ref}>
                <img src={about6} alt={'Для цивільних: ІЗ ВАШОЮ ПІДТРИМКОЮ МИ ЩЕ СИЛЬНІШІ'}/>
            </Box>
        </Container>
    )
}
