import Logo from "./Logo";
import DonateButton from "./DonateButton";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import {useState} from "react";
import {IconButton} from "@mui/material";
import Menu from "./Menu";
import {useLocation} from "react-router-dom";
import {THEME, URLs} from "../constants";

import '../assets/styles/header.scss';

export function BurgerMenu({onClick, theme = THEME.LIGHT}) {
    const line = <span></span>;

    return (<IconButton id={'burger-menu'} className={theme} onClick={onClick} disableRipple={true}>
        {line}
        {line}
        {line}
    </IconButton>);
}

function AppHeader({showMenuButton = true, showDonateButton = false}) {
    const location = useLocation();

    const darkThemeLocations = [URLs.FOUNDATION];
    const currentTheme = darkThemeLocations.includes(location.pathname) ? THEME.DARK : THEME.LIGHT;

    const [menuVisible, setMenuVisible] = useState(false);

    return (
        <AppBar
            id={'app-header'}
            className={currentTheme}
            position="fixed"
            elevation={0}>
            <Logo theme={currentTheme}/>

            {showMenuButton && <BurgerMenu theme={currentTheme} onClick={() => setMenuVisible(true)}/>}

            <Menu className={menuVisible ? 'open' : ''} onClose={() => setMenuVisible(false)}/>

            {showDonateButton && <DonateButton theme={currentTheme}/>}
        </AppBar>
    )
}

export default AppHeader;
