import * as React from "react";
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {URLs} from "../constants";


import '../assets/styles/PageNotFound.scss';

export default function PageNotFound() {
    return (
        <Box id={'page-not-found'}>
            <Box>
                <Typography variant={'h1'}>Помилка 404</Typography>
                <Typography variant={'h2'}>Далі дороги нема - там русня</Typography>

                <Typography>
                    Ви можете перейти на сторінку <Link to={URLs.REQUISITES}>Реквізити</Link> та зробити внесок, щоб
                    пришвидшити перемогу або повернутися на <Link to={'/'}>Головну</Link> сторінку сайту
                </Typography>
            </Box>
        </Box>
    )
}
