import copy from "clipboard-copy";
import * as React from "react";
import {Tooltip} from "@mui/material";
import {useState} from "react";


function CopyToClipboard (props) {
    const [clickToCopy, setClickToCopy] = useState(false);

    const handleOnTooltipClose = () => {
        setClickToCopy(false)
    };

    const onCopy = (content) => {
        copy(content).then();
        setClickToCopy(true)
    };


        return (
            <Tooltip
                title={clickToCopy ? (props.titleCopied || "Скопійовано до буферу обміну") : (props.title || 'Натисніть, щоб скопіювати')}
                onClose={handleOnTooltipClose}
                {...props.TooltipProps || {}}
            >
                {props.children({ copy: onCopy })}
            </Tooltip>
        );
}

export default CopyToClipboard;
