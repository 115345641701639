import * as React from "react";
import {
    Box,
    Container,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";

import qr from '../../assets/images/qr-code.svg';
import googlePay from '../../assets/images/google_pay.png';
import applePay from '../../assets/images/apple_pay.png';
import copyIcon from '../../assets/images/copy-icon.png';
import monoIcon from '../../assets/images/mono-icon.png';
import paypalIcon from '../../assets/images/paypal-icon.png';

import '../../assets/styles/Requisites.scss';

import jar from '../../assets/images/markus-donation-wb.png'
import {TableButton} from "./index";
import DronesFundraiser from "./DronesFundraiser";

export default function Requisites() {
    return (
        <Container id={'requisites'}>
            <Typography className={'support'}>підтримай 47 бригаду</Typography>

            <Typography variant={'h1'}>Реквізити благодійного фонду Markus Foundation</Typography>

            <DronesFundraiser/>

            <Typography className={'subtitle'}>
                ТАКОЖ ВИ МОЖЕТЕ ЗРОБИТИ ВНЕСОК ДО НАШОГО БЛАГОДІЙНОГО ФОНДУ БУДЬ-ЯКИМ ЗРУЧНИМ ДЛЯ ВАС СПОСОБОМ
            </Typography>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>OTP Банк:</TableCell>
                        <TableCell>5309 2127 3211 5298</TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'5309212732115298'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Приват Банк:</TableCell>
                        <TableCell>4731 2196 3102 6238</TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'4731 2196 3102 6238'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>УкрСиб Банк:</TableCell>
                        <TableCell>5228 7155 1393 4024</TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'5228 7155 1393 4024'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Моно Банк:</TableCell>
                        <TableCell>5375 4141 1632 6520</TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'5375 4141 1632 6520'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow className={'no-border'}>
                        <TableCell></TableCell>
                        <TableCell>
                            <Typography></Typography>
                            <Box>
                                <img id={'monobank-jar'} src={jar} alt={'Монобанка'}/>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <TableButton icon={monoIcon}
                                         href={'https://bit.ly/3NW2tux'}>Поповнити</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow className={'no-border'}>
                        <TableCell></TableCell>
                        <TableCell>
                            <Typography></Typography>
                            <Box>
                                <img id={'google-pay'} src={googlePay} alt={'Google Pay'}/>
                                <img id={'apple-pay'} src={applePay} alt={'Apple Pay'}/>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <TableButton icon={monoIcon}
                                         href={'https://pay.mbnk.biz/Ht-Ezt4bcZbB'}>Переказати</TableButton>
                        </TableCell>
                    </TableRow>
                    {/*<TableRow>*/}
                    {/*    <TableCell>Payoneer:</TableCell>*/}
                    {/*    <TableCell>vvs.vasiliev@gmail.com</TableCell>*/}
                    {/*    <TableCell>*/}
                    {/*        <TableButton icon={copyIcon} textToCopy={'vvs.vasiliev@gmail.com'}>Копіювати</TableButton>*/}
                    {/*    </TableCell>*/}
                    {/*</TableRow>*/}
                    <TableRow>
                        <TableCell>PayPal:</TableCell>
                        <TableCell>vvs.vasiliev@gmail.com</TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'vvs.vasiliev@gmail.com'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow className={'no-border'}>
                        <TableCell></TableCell>
                        <TableCell>
                            <Typography>Перейти на сайт PayPal для<br/>
                                переказу коштів</Typography>
                        </TableCell>
                        <TableCell>
                            <TableButton icon={paypalIcon}
                                         href={'https://www.paypal.com/donate/?hosted_button_id=TL6PF26ZZRMYG'}>Переказати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow className={'no-border'} id={'qr-code'}>
                        <TableCell></TableCell>
                        <TableCell>
                            <Typography>Або просто наведіть камеру<br/>
                                смартфону на QR kод, щоб<br/>
                                швидко перевести кошти
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <img src={qr} alt={'QR-код'} style={{maxWidth: '150px'}}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Криптовалюта</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>BTC:</TableCell>
                        <TableCell className={'normal-case'}>16yqRqu2y9WVhaByeZD4ZxBNYsA8TRb99W</TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'16yqRqu2y9WVhaByeZD4ZxBNYsA8TRb99W'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>ETH:</TableCell>
                        <TableCell className={'normal-case'}>0xce2497a387c31c57eb0257d1b00c37b4ee4c732f</TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'0xce2497a387c31c57eb0257d1b00c37b4ee4c732f'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>TRC20:</TableCell>
                        <TableCell className={'normal-case'}>TUCi52wocgdcehYf621hX1715ESYvGgbyy</TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'TUCi52wocgdcehYf621hX1715ESYvGgbyy'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table><Table className={'money-transfers'}>
            <TableBody>
                <TableRow>
                    <TableCell colSpan={3}>(UAH)</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Найменування одержувача:</TableCell>
                    <TableCell>БЛАГОДІЙНА ОРГАНІЗАЦІЯ «БЛАГОДІЙНИЙ ФОНД „МАРКУС ФУНДЕЙШН“»
                    </TableCell>

                    <TableCell>
                        <TableButton icon={copyIcon}
                                     textToCopy={'БЛАГОДІЙНА ОРГАНІЗАЦІЯ «БЛАГОДІЙНИЙ ФОНД „МАРКУС ФУНДЕЙШН“»'}>Копіювати</TableButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Код одержувача:</TableCell>
                    <TableCell>44804009</TableCell>

                    <TableCell>
                        <TableButton icon={copyIcon} textToCopy={'44804009'}>Копіювати</TableButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Назва банку:</TableCell>
                    <TableCell>Головне Управління по м.Києву та Київській області АТ "Ощадбанк"</TableCell>

                    <TableCell>
                        <TableButton icon={copyIcon}
                                     textToCopy={'Головне Управління по м.Києву та Київській області АТ "Ощадбанк" \n'}>Копіювати</TableButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Рахунок одержувача у<br/>форматі IBAN:</TableCell>
                    <TableCell>UA963226690000026009300743544</TableCell>

                    <TableCell>
                        <TableButton icon={copyIcon}
                                     textToCopy={'UA963226690000026009300743544'}>Копіювати</TableButton>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
            <Table className={'money-transfers'}>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={3}>(UAH)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Найменування одержувача:</TableCell>
                        <TableCell>БО 'БФ 'МАРКУС ФУНДЕЙШН'</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'БО \'БФ \'МАРКУС ФУНДЕЙШН\''}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Код одержувача:</TableCell>
                        <TableCell>44804009</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'44804009'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Назва банку:</TableCell>
                        <TableCell>АТ КБ «ПриватБанк»</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'АТ КБ «ПриватБанк»'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Рахунок одержувача у<br/>форматі IBAN:</TableCell>
                        <TableCell>UA103052990000026004046221358</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'UA103052990000026004046221358'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table className={'money-transfers'}>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={3}>(USD)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Сompany Name:</TableCell>
                        <TableCell>CO"CHARITABLE FOUNDATION <br/> "MARCUS FOUNDATION"</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'CO"CHARITABLE FOUNDATION "MARCUS FOUNDATION"'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>IBAN Code:</TableCell>
                        <TableCell>UA483052990000026008006234325</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'UA483052990000026008006234325'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name of the bank:</TableCell>
                        <TableCell>JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO
                            STR., KYIV, 01001, UKRAINE</TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Bank SWIFT Code:</TableCell>
                        <TableCell>PBANUA2X</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'PBANUA2X'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Company address:</TableCell>
                        <TableCell>02141, Ukraine, Kiev, Mishugi
                            Oleksandra st., 10, office 405
                        </TableCell>
                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Correspondent banks Account in the correspondent bank:</TableCell>
                        <TableCell>001-1-000080</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'001-1-000080'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>SWIFT Code of the
                            correspondent bank:</TableCell>
                        <TableCell>CHASUS33</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'CHASUS33'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Correspondent bank:</TableCell>
                        <TableCell>JP Morgan Chase Bank, New York ,USA</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'JP Morgan Chase Bank, New York ,USA'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Account in the
                            correspondent bank:</TableCell>
                        <TableCell>890-0085-754</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'890-0085-754'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>SWIFT Code of the
                            correspondent bank:</TableCell>
                        <TableCell>IRVT US 3N</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'IRVT US 3N'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Correspondent bank:</TableCell>
                        <TableCell>The Bank of New York Mellon, New York, USA</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'The Bank of New York Mellon, New York, USA'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow></TableBody>
            </Table>
            <Table className={'money-transfers'}>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={3}>(EUR)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Сompany Name:</TableCell>
                        <TableCell>CO"CHARITABLE FOUNDATION <br/> "MARCUS FOUNDATION"</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'CO"CHARITABLE FOUNDATION "MARCUS FOUNDATION"'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>IBAN Code:</TableCell>
                        <TableCell>UA843052990000026002046220094</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'UA843052990000026002046220094'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name of the bank:</TableCell>
                        <TableCell>JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Bank SWIFT Code:</TableCell>
                        <TableCell>PBANUA2X</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'PBANUA2X'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Company address:</TableCell>
                        <TableCell>02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Correspondent banks Account in the correspondent bank:</TableCell>
                        <TableCell>400886700401</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'400886700401'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>SWIFT Code of the correspondent bank:</TableCell>
                        <TableCell>COBADEFF</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'COBADEFF'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Correspondent bank:</TableCell>
                        <TableCell>Commerzbank AG, Frankfurt am Main, Germany</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'Commerzbank AG, Frankfurt am Main, Germany'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Account in the correspondent bank:</TableCell>
                        <TableCell>6231605145</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'6231605145'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>SWIFT Code of the correspondent bank:</TableCell>
                        <TableCell>CHASDEFX</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'CHASDEFX'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Correspondent bank:</TableCell>
                        <TableCell>J.P.MORGAN AG, FRANKFURT AM MAIN, GERMANY</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'J.P.MORGAN AG, FRANKFURT AM MAIN, GERMANY'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table className={'money-transfers'}>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={3}>(PLN)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Сompany Name:</TableCell>
                        <TableCell>CO"CHARITABLE FOUNDATION <br/> "MARCUS FOUNDATION"</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'CO"CHARITABLE FOUNDATION "MARCUS FOUNDATION"'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>IBAN Code:</TableCell>
                        <TableCell>UA463052990000026002046234552</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'UA463052990000026002046234552'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name of the bank:</TableCell>
                        <TableCell>JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Bank SWIFT Code:</TableCell>
                        <TableCell>PBANUA2X</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'PBANUA2X'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Company address:</TableCell>
                        <TableCell>02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Correspondent banks Account in the correspondent bank:</TableCell>
                        <TableCell>PL13124000013140533111120301</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'PL13124000013140533111120301'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>SWIFT Code of the correspondent bank:</TableCell>
                        <TableCell>PKOPPLPW</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon} textToCopy={'PKOPPLPW'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Correspondent bank:</TableCell>
                        <TableCell>Bank Pekao/Grupa Pekao S.A., Warsaw, Poland</TableCell>

                        <TableCell>
                            <TableButton icon={copyIcon}
                                         textToCopy={'Bank Pekao/Grupa Pekao S.A., Warsaw, Poland'}>Копіювати</TableButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Container>
    )
}
