import {Box, Container, Grid, Typography} from "@mui/material";

export default function BrigadeValues() {
    return (
        <Box id={'brigade-values'}>
            <Container>
                <Grid container>
                    <Grid item md={6}>
                        <Typography className={'subtitle'}>47 бригада</Typography>
                        <Typography variant={'h2'}>ЦІННОСТІ 47 БРИГАДИ</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={'text'}>
                            Основними нашими пріоритетами є безперервне навчання, якісна внутрішня культура та
                            взаємоповага між усіма ланками всередині військової частини
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={'column-num'}>01</Typography>

                        <Typography className={'column-title'}>Принцип безперервного навчання</Typography>

                        <Typography className={'column-text'}>Головний принцип нашого 47 підрозділу – постійне навчання.
                            Ми створюємо всі можливі умови для якісної підготовки особового складу – техніка, зброя,
                            простір та час. Спочатку боєць вивчає свій напрямок, потім — додаткові. Кожен повинен мати
                            відмінні навички поводження зі зброєю, вміти працювати з вибухівкою та надавати компетентну
                            домедичну допомогу. </Typography>

                        <Typography className={'column-text'}>Даємо все необхідне кожному, чиє щире бажання – вчитися.
                            Хто вчитися не хоче – у нас не служить.
                        </Typography>

                        <Typography className={'column-text'}>Нам не потрібні персонажі з категорії “я і так все знаю”,
                            які не мають прагнення до самоосвіти. </Typography>

                        <Typography className={'column-text'}>Знаходимо та залучаємо військових інструкторів найвищого
                            рівня. Це іноземні фахівці з приватних компаній, які займаються професійною підготовкою
                            особового складу армій багатьох країн світу. Рядових, сержантів, офіцерів. Навчають усього –
                            від базових речей до процесів найвищого рівня, наданню домедичної допомоги. Тренують
                            саперів, операторів безпілотних літальних апаратів (БПЛА), артилеристів та снайперів.
                            Інструктори – виключно з реальним досвідом, що забезпечує корисний результат, а не процес
                            заради процесу.
                        </Typography>

                        <Typography className={'column-text'}>Кожен воїн має ефективно досягати поставлених задач та
                            навчатися, виконувати бойові накази так якісно, як тільки можливо. Інакше у нас не
                            затримається. </Typography>

                    </Grid>
                    <Grid item md={6}>
                        <Typography className={'column-num'}>02</Typography>

                        <Typography className={'column-title'}>Виховання внутрішньої культури воїна</Typography>

                        <Typography className={'column-text'}>Бригада, як і колись батальйон та полк, розвиває свій
                            унікальний бойовий дух, творить власну історію, традиції, віднаходить особливий підхід до
                            комплектування особовим складом.
                            Виховання внутрішньої культури бійця, формулювання ясної ідеології – один із найважливіших
                            аспектів роботи з командою. Ми приділяємо цьому багато часу. Важливі й підтримка ритуалів,
                            які спрямовані на те, аби кожен пишався місцем своєї служби; і дотримання правил, що
                            працюють на субординацію, дисципліну та якість виконання завдань. </Typography>


                        <Typography className={'column-text'}>Ми прагнемо побудувати міцний фундамент для ефективності
                            воїна у всьому, що він робить – це чітке розуміння того, в чому полягає його місія. Нам не
                            цікаво просто мотивувати людей — ми хочемо мотивувати їх аргументовано, а не інфантильно.
                            Щоб наші бійці тверезо усвідомлювали, хто вони й для чого вони тут.</Typography>

                        <Typography className={'column-text'}>Усвідомлювали себе як громадяни України, які розуміють, що
                            означає для них ця країна.</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={'column-num'}>03</Typography>

                        <Typography className={'column-title'}>Усі і на всіх рівнях мають бути
                            ефективними</Typography>

                        <Typography className={'column-text'}>Всі елементи підрозділу повинні працювати ефективно на
                            всіх рівнях. Рядовий, сержант, офіцер – кожен має бути ефективним. Не просто забивати штат,
                            а приносити користь щодня. Своєму підрозділу, своїй країні.</Typography>

                    </Grid>
                    <Grid item md={6}>
                        <Typography className={'column-num'}>04</Typography>

                        <Typography className={'column-title'}>Ніхто не працює на процес — усі
                            працюють на результат</Typography>

                        <Typography className={'column-text'}>Саме такий принцип, реалізований кожним на його ділянці
                            роботи, рухає нас в одному, правильному напрямку.</Typography>
                    </Grid>
                </Grid>

            </Container>
        </Box>
    )
}
