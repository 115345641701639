import {Box, Divider, Grid, Typography} from "@mui/material";
import expansion from "../../../assets/images/about-expansion.jpg";


export default function SeparateAssaultRegiment() {
    const isVisible = false;

    return (
        <Box id={'separate-assault-regiment'} className={'template image-center'}>
            <Box className={'image-wrapper'}>
                <img src={expansion} alt={'47 бригада'}/>
            </Box>
            <Grid container>
                <Grid item md={5} className={isVisible ? 'fadeInLeft' : ''}>
                    <Box className={'textbox'}>
                        <Typography className={'title'}>47 бригада</Typography>

                        <Divider className={'divider'}/>

                        <Typography className={'subtitle'}>РОЗШИРЕННЯ ВІД БАТАЛЬЙОНУ ДО БРИГАДИ</Typography>
                    </Box>
                </Grid>
                <Grid className={'text' + (isVisible ? ' fadeInRight' : '')} item md={7}>
                    28 червня 2022 року оголошено, що батальйон розширюється до Окремого Штурмового Полку. Штатну
                    організаційну структуру підрозділу складено разом з офіцерами Генштабу.
                    <br/>
                    <br/>
                    Влітку був відновлений відбір добровольців до лав підрозділу. Вимоги до кандидатів високі.
                    <br/>
                    "Якщо ви не здатні до самоосвіти – ви нам не підходите. Не здатні брати на себе відповідальність в
                    критичних ситуаціях із загрозою втрати життя, проявляти ініціативу – ви нам не підходите. Не здатні
                    спілкуватися, як цивілізована людина – не підходите. Не визнаєте субординацію, не приймаєте команди
                    й критику від командирів молодших від вас за віком – не підходите. Не розумієте української та не
                    здатні швидко, в критичних умовах, вести перемовини в радіоефірі українською – не підходите. Не
                    здатні пробігти 10 км – не підходите.", – наголосив Валерій Маркус, повідомляючи новину про
                    створення полку.
                    <br/>
                    <br/>
                    У жовтні Валерій Маркус повідомив про розширення 47 окремого штурмового полку до 47 бригади.
                </Grid>
            </Grid>
        </Box>
    )
}
