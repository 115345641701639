import * as React from "react";
import MainScreen from "./sections/MainScreen";
import About from "./sections/About";
import Abroad from "./sections/Abroad";

import '../../assets/styles/Home.scss';

export default function Home() {
    return (
        <React.Fragment>
            <MainScreen/>
            <About/>
            <Abroad/>
        </React.Fragment>
    )
}
