import {Box, Container, Typography} from "@mui/material";

import aboutMission from '../../../assets/images/about-mission.jpg';
import aboutGoals from '../../../assets/images/about-main-goals.jpg';
import {useRef} from "react";
import useOnScreen from "../../../utils/useOnScreen";

export default function Mission() {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <Box id={'brigade-mission'}>
            <Box className={'text-with-background mission'} ref={ref}>
                <img src={aboutMission} alt={'МІСІЯ 47 БРИГАДИ'}/>
                <Box className={'text' + (isVisible ? ' fadeIn' : '')}>
                    <Typography variant={'h2'}>МІСІЯ 47 БРИГАДИ</Typography>
                    <Typography>
                        Наша основна місія – змінити застарілий підхід української армії до формування та функціонування
                        нових підрозділів. В основі мають бути інші засади – повага, безперервне навчання, розвиток
                        внутрішньої культури.
                    </Typography>
                </Box>
            </Box>
            <Box className={'cooperation'}>
                <Container>
                    <Typography variant={'h3'}>
                        47 БРИГАДА В ТІСНІЙ СПІВПРАЦІ З БЛАГОДІЙНИМ ФОНДОМ MARKUS FOUNDATION СТВОРЮЄ УМОВИ ДЛЯ НАВЧАННЯ
                        ТА ЗАБЕЗПЕЧЕННЯ БІЙЦІВ НЕОБХІДНИМ ОСНАЩЕННЯМ.
                    </Typography>
                </Container>
            </Box>
            <Box className={'text-with-background goals'}>
                <img src={aboutGoals} alt={'ГОЛОВНІ ЗАДАЧІ КЕРІВНИЦТВА 47 БРИГАДИ'}/>
                <Box className={'text' + (isVisible ? ' fadeIn' : '')}>
                    <Typography variant={'h2'}>
                        ГОЛОВНІ ЗАДАЧІ КЕРІВНИЦТВА 47 БРИГАДИ
                    </Typography>
                    <Typography>
                        Забезпечити мобільність та навченість бійців. Наша роль полягає в тому, щоб слідувати в
                        авангарді атаки, застати ворога зненацька і вести зосереджений вогонь.
                    </Typography>
                </Box>
            </Box>
            <Box className={'quote'}>
                <Typography variant={'h3'}>Ми ставимо собі за мету не тільки вигнати і знищити ворога з нашої землі,
                    але й на базі одного підрозділу провести невелику реформу функціонування військової частини. Можливо
                    наш досвід буде в майбутньому використаний в інших підрозділах ЗСУ.</Typography>

                <Typography>
                    "На жаль, армія в багатьох аспектах — архаїчна структура, почасти радянська, і мало хто хоче
                    по-справжньому змін. Бюрократія, неефективна робота з людьми, застарілі процеси навчання,
                    недостатнє
                    забезпечення. Нам, як людям із великим бойовим досвідом ці проблеми знайомі. І ми маємо
                    розуміння, як ці процеси можна налаштувати.", – зазначає Валерій Маркус.
                </Typography>
            </Box>
        </Box>
    )
}
