import {Box, Container} from "@mui/material";
import {URLs} from "../../../constants";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";

export default function MainScreen() {
    return (<Box id={'main'}>
        <Container className={'fadeIn'}>
            <Box>
                <h1>Благодійний фонд Markus Foundation</h1>

                <Box className={'text'}>надійний тил 47 бригади ЗСУ. І водночас – це кожен, хто підтримує діяльність благодійного фонду матеріально
                    та/або інформаційно.
                </Box>

                <Box className={'text extra'}>
                    <b>Markus Foundation</b> створений аби вирішувати одразу кілька важливих і
                    нагальних питань:
                    <ul style={{paddingLeft: '20px'}}>
                        <li>матеріально-технічне забезпечення 47 бригади;</li>
                        <li>навчання і тренування бійців із залученням інструкторів провідних армій світу;</li>
                        <li>підтримка поранених і сімей загиблих героїв.</li>
                    </ul>
                </Box>

                <Button className={'btn btn-default'} component={Link} to={URLs.FOUNDATION}>
                    Детальніше
                </Button>
            </Box>
        </Container>
    </Box>)
}
