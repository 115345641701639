import * as React from "react";
import '../../assets/styles/Content.scss';
import {Box, Typography} from "@mui/material";

export default function Video4() {
    const videoUrl = '/content/video-4.MP4';

    return (
        <React.Fragment>
            <Box id={'content-box'}>
                <Box id={'video-card'}>
                    <video controls muted src={videoUrl}></video>

                    <Box className={'video-details'}>
                        <small className={'source'}>Джерело: офіційний акаунт 47 ОМБр <a
                            href={'https://t.me/brygada47/80'}>https://t.me/brygada47/80</a></small>
                        <small className={'date'}>13 січня 2024 року</small>
                    </Box>
                </Box>

                <Box className={'content-body'}>
                    <Typography>БМП Бредлі проти ворожого танку Т-90М в районі Степового, що на Авдіївському напрямку.</Typography>

                    <Typography>Перемогла американська техніка. Екіпаж 47 ОМБр вивів із ладу систему російського танку, у якого почала без зупинку крутитися башта.</Typography>

                    <Typography>А наша славетна рота ударних БпЛА добила машину за допомогою FPV-дрона.</Typography>

                    <Typography>Слава воїнам 47 окремої механізованої бригади!</Typography>
                </Box>
            </Box>

        </React.Fragment>
    )
}
