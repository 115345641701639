import * as React from "react";
import '../../assets/styles/Content.scss';
import {Box, Typography} from "@mui/material";

export default function Video1() {
    const videoUrl = '/content/video-1.MP4';

    return (
        <React.Fragment>
            <Box id={'content-box'}>
                <Box id={'video-card'}>
                    <video controls muted src={videoUrl}></video>

                    <Box class={'video-details'}>
                        <small className={'source'}>Джерело: офіційний акаунт 47 ОМБр <a
                            href={'https://t.me/brygada47/80'}>https://t.me/brygada47/80</a></small>
                        <small className={'date'}>22 серпня 2023 року</small>
                    </Box>
                </Box>

                <Box className={'content-body'}>
                    <Typography>Воїни 47 окремої механізованої бригади зайшли в село РОБОТИНЕ, що на Мелітопольському
                        напрямку!</Typography>

                    <Typography>Два місяці запеклих боїв й бійці нашої бригади за підтримки інших підрозділів прорвали
                        багатоешелоновану лінію оборони окупантів!</Typography>

                    <Typography>Перше, що зробили військовослужбовці – сповістили місцевих про необхідність терміново
                        виїжджати, адже ворог цинічно продовжує стирати село з лиця землі.</Typography>

                    <Typography>«Півтора року чекали на ЗСУ. Ми похапали речі, документи та бігли, дорогою викидаючи ті
                        пакети, бо життя – найцінніше. А потім ці хлопці з 47 бригади вивезли нас на танку», - так люди
                        назвали американську БМП Бредлі, яка витримує підриви на мінах та прямі влучання ворожої
                        артилерії.</Typography>

                    <Typography>Вже в більш безпечному місці медики 47 окремої механізованої бригади оглянули цивільних
                        та
                        дали можливість подзвонити рідним.</Typography>

                    <Typography>Кадри з села Роботине ми оприлюднимо дещо згодом задля БЕЗПЕКИ наших воїнів. Тривають
                        бої й
                        ми закріплюємося на досягнутих рубежах.</Typography>

                    <Typography>Вірте в ЗСУ! Вірте в бійців 47 окремої механізованої бригади!</Typography>
                </Box>
            </Box>

        </React.Fragment>
    )
}
