import {Box, Divider, Grid, Typography} from "@mui/material";

import about4 from '../../../assets/images/about-4.jpg';
import {useRef} from "react";
import useOnScreen from "../../../utils/useOnScreen";

export default function Interviews() {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <Box ref={ref} id={'interviews'} className={'template image-center'}>
            <Box className={'image-wrapper'}>
                <img src={about4} alt={'Як створювалась 47 бригада?'}/>
            </Box>
            <Grid container>
                <Grid className={isVisible ? 'fadeInLeft' : ''} item md={5}>
                    <Box className={'textbox'}>
                        <Typography className={'title'}>47 БРИГАДА</Typography>

                        <Divider className={'divider'}/>

                        <Typography className="subtitle">Співбесіди</Typography>
                    </Box>
                </Grid>
                <Grid className={'text' + (isVisible ? ' fadeInRight' : '')} item md={7}>
                    У травні комбат ще тоді 47 окремого штурмового батальйону Іван Шаламага та головний сержант Маркус
                    мали відвідати Київ, Дніпро, Одесу та Львів, щоб особисто провести співбесіди та відібрати кілька
                    сотень бійців, які складуть кістяк підрозділу. Проте вже на першій зустрічі в столиці охочих
                    виявилося дуже багато. Тому ухвалили рішення скасувати візити до інших міст та обмежитися
                    добровольцями, які прибули до Києва.
                    <br/>
                    <br/>
                    Було відібрано велику кількість гідних чоловіків та жінок – представників різних військових
                    спеціальностей: кулеметників, снайперів, саперів, командирів відділень та взводів. Тим, хто вже
                    перебував на службі, підготували необхідні документи для переведення.
                    <br/>
                    <br/>
                    У сучасному військовому підрозділі знайшлося місце і для людей з бойовим досвідом, і для цілком
                    цивільних. У наш час, за допомогою новітніх технічних засобів учорашній айтівець також здатний
                    ефективно знищувати ворога не у комп’ютерній грі, а на реальному полі бою. Окрім того, юристи,
                    діловоди, бухгалтери, кухарі… Ці професії актуальні для армії. Адже потрібно і вчасно складати
                    важливі документи, і готувати обід для солдатів.
                </Grid>
            </Grid>

            <Box className={'quote' + (isVisible ? ' fadeInDown' : '')}>
                <Box style={{
                    justifyContent: 'center',
                    display: 'flex'
                }}>
                    <Divider orientation={'vertical'}
                             style={{
                                 height: '3rem',
                                 borderColor: '#5e6773',
                                 borderRightWidth: '3px'
                             }}/>
                </Box>

                <Typography variant={'h3'}>
                    "Якщо ви чогось не вмієте, не страшно, головне, щоб у вас були прямі руки,
                    твереза голова, бажання розвиватися та адекватна оцінка своїх можливостей, а всьому, що потрібно, ми
                    вас
                    навчимо",
                </Typography>

                <Typography className={'caption' + (isVisible ? ' fadeInUp' : '')}>
                    - писав ветеран війни на Донбасі Валерій Маркус<br/>у своєму телеграмі.
                </Typography>

            </Box>
        </Box>
    )
}
