import {Box, Typography} from "@mui/material";

import joinTheVictory from '../../../assets/images/join-the-victory.jpg';
import {Link} from "react-router-dom";
import {useRef} from "react";
import useOnScreen from "../../../utils/useOnScreen";
import {URLs} from "../../../constants";
import Button from "@mui/material/Button";

export default function JoinTheVictory() {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <Box ref={ref} id={'join-the-victory'}>
            <Box className={'image'}>
                <img src={joinTheVictory} alt={'Долучитися до перемоги'}/>
            </Box>
            <Box className={'text' + (isVisible ? ' fadeIn' : '')}>
                <Typography variant={'h3'}>Долучитися до перемоги</Typography>

                <Typography>
                    Будемо щирі, ми всі тут зібралися задля двох речей: наваляти добряче русні та здобути перемогу. І
                    якщо перше — приємний процес, то перемога — результат, який потребує нашої з вами спільної та
                    системної роботи.
                    <br/>
                    <br/>
                    Перемога народжується не тільки на фронті. Вона у кожному закритому зборі та кожному придбаному
                    дроні. У кожному донаті — і абсолютно не важливо, якого розміру.
                    <br/>
                    <br/>
                    Ми перемагаємо щодня, коли всі ефективні на своїх місцях. І маємо перемагати доти, поки не здобудемо
                    остаточної свободи та незалежності України.
                </Typography>

                <Box style={{marginTop: '2rem'}}>
                    <Button className={'btn btn-gold'} component={Link} to={URLs.REQUISITES}>Перемогти</Button>
                </Box>
            </Box>
        </Box>
    )
}
