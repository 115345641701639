import {Box, Container, Typography} from "@mui/material";

import about3 from '../../../assets/images/about-3.jpg';
import {useRef} from "react";
import useOnScreen from "../../../utils/useOnScreen";

export default function MeetingWithCommanderInChief() {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <Container id={'meeting-with-commander-in-chief'} className={'template image-right'}>
            <Box className={'text-wrapper' + (isVisible ? ' fadeInLeft' : '')}>
                <Typography variant={'h2'}>
                    Зустріч із Залужним
                </Typography>
                <Typography className={'text'}>
                    Наприкінці травня відбулася зустріч Валерія Маркуса з Головнокомандувачем Збройних Сил України, де
                    йшлося про долю підрозділу. Валерій Залужний підтримав ідеї командування 47 Штурмового полку та
                    розпорядився надати необхідну підтримку його розвитку з боку Генерального Штабу.
                </Typography>
            </Box>

            <Box className={'image-wrapper' + (isVisible ? ' fadeInRight' : '')} ref={ref}>
                <img src={about3} alt={'Зустріч із Залужним'}/>
            </Box>
        </Container>
    )
}
