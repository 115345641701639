import * as React from "react";
import {
    Box,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";

import ImageGallery from 'react-image-gallery';

import '../../assets/styles/Reports.scss';

import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import JoinTheVictory from "../brigade/sections/JoinTheVictory";
import * as axios from 'axios';

const API_URL = 'https://foundation-admin.datum.llc/api';

export default function Reports() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        axios.get(`${API_URL}/report-items`).then(res => {
            if (res?.data?.length) {
                setItems(res.data)
            }
        })
    }, [])

    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));

    const [selectedItem, setSelectedItem] = useState();

    const onToggleGallery = (item) => {
        setSelectedItem(item);
    }

    const handleClose = () => {
        setSelectedItem(undefined);
    }

    useEffect(() => {
        const htmlElement = document.querySelector('html');

        if (htmlElement) {
            if (selectedItem && !htmlElement.classList.contains('overflow-hidden')) {
                htmlElement.classList.add('overflow-hidden');
            } else {
                htmlElement.classList.remove('overflow-hidden');
            }
        }
    }, [selectedItem]);

    return (
        <React.Fragment>
            <Container id={'reports-title'}>
                <Box className={'fadeInDown divider'}></Box>
                <Typography className={'fadeInDown subtitle'}>ПІДТРИМАЙ 47 БРИГАДУ</Typography>

                <Typography className={'fadeInDown'} variant={'h1'}>Закупівлі благодійного фонду Markus
                    Foundation</Typography>

                <Box className={'fadeInUp text'}>
                    ДРУЗІ! НА ЦІЙ СТОРІНЦІ МИ РЕГУЛЯРНО ПУБЛІКУЄМО ІНФОРМАЦІЮ ПРО ПРИДБАННЯ, ЗРОБЛЕНІ ФОНДОМ, НА ЗАПИТ
                    ПІДРОЗДІЛУ. ДІЗНАЙТЕСЬ, ЯКУ САМЕ КОРИСТЬ ПРИНОСИТЬ ВАША ЩИРА ДОПОМОГА. РАЗОМ ДО ПЕРЕМОГИ!
                </Box>
            </Container>

            <Container>
                <Grid container style={{marginBottom: '3rem'}} spacing={md ? 3 : 0}>
                    {items.map((item, key) => <Grid key={key} item md={4}
                                                    style={{width: md ? '' : '100%', marginBottom: md ? 0 : '3rem'}}>
                        <Box style={{
                            width: '100%',
                            background: item.images.length > 0 ? `url(${API_URL}/uploads/${item.images[0].url}) no-repeat center center / cover` : '',
                            height: '350px',
                            marginBottom: '1rem',
                            display: 'flex',
                            alignItems: 'flex-end',
                            cursor: 'pointer'
                        }}
                             onClick={() => onToggleGallery(item)}>
                            <Typography style={{
                                width: '100%',
                                padding: '0.6rem',
                                textAlign: 'center',
                                background: 'rgba(0, 0, 0, 0.7)',
                                color: '#fff',
                                transition: 'padding .2s ease-in-out',
                                '&:hover': {
                                    paddingBottom: '2rem'
                                }
                            }}>
                                {item.title}
                            </Typography>
                        </Box>
                        {/*<Typography variant={'body2'}>{item.description}</Typography>*/}
                    </Grid>)}
                </Grid>
            </Container>

            <JoinTheVictory/>

            <Dialog fullScreen={true} open={!!selectedItem}>
                <DialogContent style={{padding: 0, display: 'flex', alignItems: 'center'}}>
                    {selectedItem && <ImageGallery
                        showPlayButton={false}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        items={selectedItem?.images.map(image => ({
                            original: API_URL + '/uploads/' + image.url
                        }))}
                    />}
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <Box>
                        <Typography
                            style={{
                                fontSize: lg ? '1.5rem' : '1rem',
                                fontWeight: 500
                            }}>{selectedItem?.title}</Typography>
                    </Box>

                    <Button onClick={handleClose}>Закрити</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
