import * as React from "react";
import '../../assets/styles/Content.scss';
import {Box, Typography} from "@mui/material";

export default function Video2() {
    const videoUrl = '/content/video-2.mov';

    return (
        <React.Fragment>
            <Box id={'content-box'}>
                <Box id={'video-card'}>
                    <video controls muted src={videoUrl}></video>

                    <Box className={'video-details'}>
                        <small className={'source'}>Джерело: офіційний акаунт 47 ОМБр <a
                            href={'https://t.me/brygada47/80'}>https://t.me/brygada47/80</a></small>
                        <small className={'date'}>29 липня 2024 року</small>
                    </Box>
                </Box>

                <Box className={'content-body'}>
                    <Typography>Кожну хвилину змінюється атмосфера від мальовничих пейзажів з цілими будинками, до
                        розбитих доріг, зруйнованих сіл і спалених вщент автівок. Все це змінюється буквально у тебе на
                        очах.</Typography>

                    <Typography>Кожна поїздка екіпажа Бредлі це подорож з невідомим сюжетом. Треба розуміти куди можна
                        проїхати, де треба почекати, а десь дослухатися до інтуїції, яка тобі може щось підказати. Це
                        щоденна невпинна праця злагодженої за час повномасштабної війни команди: механіка-водія,
                        навідника і командира машини, від взаємодії яких залежить виконання бойового завдання, життя
                        одне одного й піхоти, яку вони прикривають.</Typography>

                    <Typography>Міномети, ворожі fpv-дрони, КАБи, ствольна артилерія, все це націлено на знищення
                        екіпажу бойової машини.</Typography>

                    <Typography>Занурюйтесь в атмосферу щоденних поїздок на БМП Bradley. Працює екіпаж Кача, 3 мехбату,
                        47 окремої механізованої бригади. (<a href={'https://t.me/brygada47'}>https://t.me/brygada47</a>)</Typography>
                </Box>
            </Box>

        </React.Fragment>
    )
}
