import React from "react";
import AppHeader from "../components/AppHeader";
import {Outlet, useLocation} from "react-router-dom";
import Social from "../components/Social";
import AppFooter from "../components/AppFooter";
import {getPageMeta} from "../metatags";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Layout() {
    const location = useLocation();

    const page = location.pathname;

    const meta = getPageMeta(page);

    const canonicalUrl = process.env.REACT_APP_WEBSITE_URL + location.pathname;

    return (
        <HelmetProvider>
            {meta && <Helmet>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description}/>
                <meta property={"og:title"} content={meta.ogTitle} />
                <meta property={"og:image"} content={'/preview/v2.jpg'} />
                <meta property={"og:description"} content={meta.ogDescription} />
                <link href={canonicalUrl} rel="canonical" />
            </Helmet>}

            <AppHeader/>
            <Outlet/>
            <Social/>
            <AppFooter/>
        </HelmetProvider>
    );
}
