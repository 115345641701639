import {Box, Container, Typography} from "@mui/material";

import about2 from '../../../assets/images/about-2.jpg';
import chevron from '../../../assets/images/chevron-dark.jpg';
import useOnScreen from "../../../utils/useOnScreen";
import {useRef} from "react";

export default function HowBrigadeWasFormed() {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <Container id={'how-brigade-was-formed'} className={'template image-left'}>
            <Box ref={ref} className={'image-wrapper' + (isVisible ? ' fadeInLeft' : '')}>
                <img src={about2} alt={'ЯК СТВОРЮВАЛАСЯ 47 БРИГАДА?'}/>
            </Box>
            <Box className={'text-wrapper' + (isVisible ? ' fadeInRight' : '')}>
                <Typography variant={'h2'}>
                    ЯК СТВОРЮВАЛАСЯ 47 БРИГАДА?
                </Typography>
                <Typography
                    variant={'body1'}
                    className={'text'}>
                    У квітні 2022 року Іван Шаламага, який на той момент займав посаду замкомбата у складі славетної
                    30-ї бригади, та розвідник Валерій Маркус отримали пропозицію від ЗСУ очолити новостворений 47-й
                    штурмовий батальйон. Валерій Маркус – як головний сержант, Іван Шаламага – у якості комбата. Вони
                    поставили перед собою задачу створити не просто рядовий батальйон, а збудувати підрозділ на
                    кардинально нових засадах: <b>повага, принцип безперервного навчання, внутрішня культура.</b>
                    <br/>
                    <br/>
                    Влітку 2022 року батальйон було розширено до 47 Штурмового полку, а з листопада 2022 – до 47
                    бригади.
                </Typography>

                <Box>
                    <img src={chevron} alt={'47 бригади'}/>
                </Box>
            </Box>
        </Container>
    )
}
