import {Box, Typography} from "@mui/material";

import about1 from '../../../assets/images/about-1.jpg';

export default function Slogan() {
    return (
        <Box id={'brigade-slogan'}>
            <img className={'image'} src={about1} alt={'47 бригада'}/>
            <Box>
                <Typography variant={'h1'}>47 БРИГАДА</Typography>
                <Typography variant={'h2'}>Повага, принцип безперервного навчання, внутрішня культура.</Typography>
            </Box>
        </Box>
    )
}
