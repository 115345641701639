import * as React from "react";
import '../../assets/styles/Content.scss';
import {Box, Typography} from "@mui/material";

export default function Video3() {
    const videoUrl = '/content/video-3.MP4';

    return (
        <React.Fragment>
            <Box id={'content-box'}>
                <Box id={'video-card'}>
                    <video controls muted src={videoUrl}></video>

                    <Box className={'video-details'}>
                        <small className={'source'}>Джерело: офіційний акаунт 47 ОМБр <a
                            href={'https://t.me/brygada47/80'}>https://t.me/brygada47/80</a></small>
                        <small className={'date'}>7 червня 2024 року</small>
                    </Box>
                </Box>

                <Box className={'content-body'}>
                    <Typography>БМП Bradley розбирає впритул ворожий БТР разом з десантом!</Typography>

                    <Typography>Російські окупанти не зменшують темп штурмових дій. Епіцентр боїв – саме на Покровському
                        напрямку. Тут ворог намагається просунутися в глибину нашої території. Воїни 47 окремої
                        механізованої бригади (https://t.me/brygada47) стримують щонайменше три бойові бригад РФ. Маючи
                        величезні резерви живої сили, московити оперативно поповнюють шалені втрати та знову й знову
                        кидають в бій. Бойові дії не вщухають ні на мить.</Typography>

                    <Typography>Цей БТР, який ви бачите на відео, прорвався в населений пункт. Екіпажу Бредлі довелося
                        реагувати тут і зараз.</Typography>
                </Box>
            </Box>

        </React.Fragment>
    )
}
