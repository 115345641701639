import * as React from "react";
import Video1 from "./Video-1";
import Video2 from "./Video-2";
import Video3 from "./Video-3";
import Video4 from "./Video-4";
import Video5 from "./Video-5";

export const CONTENT_ROUTES = [
    {url: 'video-1', component: <Video1/>},
    {url: 'video-2', component: <Video2/>},
    {url: 'video-3', component: <Video3/>},
    {url: 'video-4', component: <Video4/>},
    {url: 'video-5', component: <Video5/>}
];
