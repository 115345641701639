import {Fragment} from 'react';

import {
    Box,
    Button,
    Link as ExternalLink,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@mui/material";

import {Link as RouterLink, useLocation} from 'react-router-dom';

import Logo from "./Logo";
import {URLs} from "../constants";
import '../assets/styles/Menu.scss';

export default function Menu({className = '', onClose}) {
    const location = useLocation();
    // const isMain = location.pathname === URLs.MAIN;

    const handleClose = () => {
        onClose();
    }

    const isActive = link => {
        return link === location.pathname;
    };

    return (<Box id={'menu'} className={className}>
        <Box className={'menu-title'}>
            <Logo disableLink={true}/>
            <Button onClick={() => {
                handleClose();
            }}/>
        </Box>
        <List className={'menu-links'}>
            <ListItem>
                <ListItemText className={isActive(URLs.MAIN) ? 'active' : ''}>
                    {isActive(URLs.MAIN) ? 'Головна' :
                        <RouterLink className={'menu-link'} to={URLs.MAIN} onClick={() => {
                            handleClose();
                        }}>Головна</RouterLink>}
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText className={isActive(URLs.FOUNDATION) ? 'active' : ''}>
                    {isActive(URLs.FOUNDATION) ? 'Про фонд' :
                        <RouterLink className={'menu-link'} to={URLs.FOUNDATION} onClick={() => {
                            handleClose();
                        }}>Про фонд</RouterLink>}
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText className={isActive(URLs.BRIGADE) ? 'active' : ''}>
                    {isActive(URLs.BRIGADE) ? '47 бригада' :
                        <RouterLink className={'menu-link'} to={URLs.BRIGADE} onClick={() => {
                            handleClose();
                        }}>47 бригада</RouterLink>}
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText className={isActive(URLs.REQUISITES) ? 'active' : ''}>
                    {isActive(URLs.REQUISITES) ? <Fragment>Реквізити для <br/> переказів</Fragment> :
                        <RouterLink className={'menu-link'} to={URLs.REQUISITES} onClick={() => {
                            handleClose();
                        }}>Реквізити для <br/> переказів</RouterLink>}
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText className={isActive(URLs.REPORTS) ? 'active' : ''}>
                    {isActive(URLs.REPORTS) ? 'Закупівлі' :
                        <RouterLink className={'menu-link'} to={URLs.REPORTS} onClick={() => {
                            handleClose();
                        }}>Закупівлі</RouterLink>}
                </ListItemText>
            </ListItem>
        </List>
        <Box className={'menu-footer'}>
            <Box className="column">
                <Typography className={'subtitle'}>Телефони підтримки:</Typography>
                <Typography>
                    <ExternalLink href={'tel:+380730000047'}
                                  style={{color: '#000', textDecoration: 'none'}}>+380730000047</ExternalLink>
                </Typography>
                <Typography className={'subtitle'}>По іншим питанням:</Typography>
                <Typography>
                    <ExternalLink href={'mailto:bf@markusfoundation.com'}
                                  style={{color: '#000', textDecoration: 'none'}}>bf@markusfoundation.com</ExternalLink>
                </Typography>
            </Box>
            <Box className="column">
                <Typography className={'subtitle'}>Ми у соціальних мережах:</Typography>
                <Box className={'socials'} style={{margin: '0.4rem 0 0'}}>
                    <ExternalLink className={'social-icon icon-sm facebook'} target={'_blank'}
                                  href={'https://www.facebook.com/bf.markus.foundation'}/>
                    <ExternalLink className={'social-icon icon-sm instagram'} target={'_blank'}
                                  href={'https://www.instagram.com/markus.foundation/'}/>
                    <ExternalLink className={'social-icon icon-sm twitter'} target={'_blank'}
                                  href={'https://twitter.com/MarkusFNDTN'}/>
                    <ExternalLink className={'social-icon icon-sm linkedin'} target={'_blank'}
                                  href={'https://www.linkedin.com/company/markus-foundation/'}/>
                    <ExternalLink className={'social-icon icon-sm telegram'} target={'_blank'}
                                  href={'https://t.me/markus_foundation'}/>
                </Box>
            </Box>
        </Box>
    </Box>)
}
