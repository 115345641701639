import * as React from "react";
import {Box, Container, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {URLs} from "../../constants";

export default function Sitemap() {
    return (
        <React.Fragment>
            <Container className={'default-head'}>
                <Box className={'vertical-divider fadeInDown'}/>

                <Typography className={'fadeInDown pre-title'}>підтримай 47 бригаду</Typography>

                <Typography className={'fadeInDown'} variant={'h1'}>
                    Благодійний фонд Markus Foundation. Мапа сайту
                </Typography>
            </Container>

            <Container className={'sitemap-wrapper'}>
                <Box className={'sitemap'}>
                    <ul>
                        <li>
                            <Link to={'/'}>Головна</Link>

                            <ul>
                                <li>
                                    <Link to={URLs.FOUNDATION}>Про фонд</Link>
                                </li>
                                <li>
                                    <Link to={URLs.BRIGADE}>47 бригада</Link>
                                </li>
                                <li>
                                    <Link to={URLs.REQUISITES}>Реквізити для переказів</Link>
                                </li>
                                <li>
                                    <Link to={URLs.REPORTS}>Закупівлі фонду</Link>
                                </li>
                                {/*<li>
                                <Link>Статус фонду</Link>
                            </li>*/}
                            </ul>
                        </li>
                    </ul>
                </Box>
            </Container>
        </React.Fragment>
    )
}
