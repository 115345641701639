import {Box, Container, Link, Typography} from "@mui/material";

import Chevron from '../assets/images/chevron.png';
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton
} from "react-share";
import {useLocation} from "react-router-dom";

export default function SocialEn() {
    const location = useLocation();

    const url = process.env.REACT_APP_WEBSITE_URL + location.pathname;

    return (<Container id={'social'}>
        <Typography variant={'h2'}>TELL YOUR FRIENDS ABOUT 47th Brigade</Typography>

        <Typography className={'text'}>
            It takes a lot of money to equip a new unit, but with your help we can do it. In turn, our 47th Brigade
            guarantees to provide photo & video reports of their training and work results on our social media. Please,
            join us at:

            <br/>
            By sharing this site with as many friends as possible, you are making one step forward towards peace in
            Ukraine.
        </Typography>

        <Box className={'socials'}>
            <FacebookShareButton url={url}><Link
                className={'social-icon facebook'}/></FacebookShareButton>
            <TwitterShareButton url={url}><Link
                className={'social-icon twitter'}/></TwitterShareButton>
            <WhatsappShareButton url={url}><Link
                className={'social-icon whatsapp'}/></WhatsappShareButton>
            <TelegramShareButton url={url}><Link
                className={'social-icon telegram'}/></TelegramShareButton>
            <ViberShareButton url={url}><Link
                className={'social-icon viber'}/></ViberShareButton>
        </Box>

        <img src={Chevron} alt={'47 бригада (шеврон)'} style={{marginTop: '2.5rem', width: '150px'}}/>

        <Typography className={'support'}>Support</Typography>
        <Typography className={'hashtag'}>THE 47th BRIGADE</Typography>
    </Container>)
}
