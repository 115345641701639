import {Box, Container, Link, Typography} from "@mui/material";

import Chevron from '../assets/images/chevron.png';
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton
} from "react-share";
import {useLocation} from "react-router-dom";

export default function Social() {
    const location = useLocation();

    const url = process.env.REACT_APP_WEBSITE_URL + location.pathname;

    return (<Container id={'social'}>
        <Typography variant={'h2'}>
            ПОДІЛИСЬ ЦИМ САЙТОМ
        </Typography>

        <Typography className={'text'}>
            На те, щоб укомплектувати новий підрозділ потрібно дуже багато коштів, і без вашої допомоги нам не обійтися.
            Своєю чергою наша 47 бригада гарантує відеозвіти з демонстрацією, як ворог змінює свій агрегатний стан.
            <br/>
            <br/>
            Поділившись цим сайтом з максимальною кількістю знайомих, ви робите ще один крок до перемоги.
        </Typography>

        <Box className={'socials'}>
            <FacebookShareButton url={url}><Link
                className={'social-icon facebook'}/></FacebookShareButton>
            <TwitterShareButton url={url}><Link
                className={'social-icon twitter'}/></TwitterShareButton>
            <WhatsappShareButton url={url}><Link
                className={'social-icon whatsapp'}/></WhatsappShareButton>
            <TelegramShareButton url={url}><Link
                className={'social-icon telegram'}/></TelegramShareButton>
            <ViberShareButton url={url}><Link
                className={'social-icon viber'}/></ViberShareButton>
        </Box>

        <img src={Chevron} alt={'47 бригада (шеврон)'} style={{marginTop: '2.5rem', width: '150px'}}/>

        <Typography className={'support'}>Підтримай</Typography>
        <Typography className={'hashtag'}>47 бригаду</Typography>
    </Container>)
}
