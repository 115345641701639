import background from '../../../assets/images/DSC05209.jpg';
import {Box, Container, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {useRef} from "react";
import useOnScreen from "../../../utils/useOnScreen";
import {URLs} from "../../../constants";
import Button from "@mui/material/Button";

export default function About() {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (<Container id={'about'}>
        <Box ref={ref} className={'image' + (isVisible ? ' fadeInLeft' : '')}>
            <img src={background} alt={'47 бригада'}/>
        </Box>

        <Box className={'container-text' + (isVisible ? ' fadeInRight' : '')}>
            <Typography id={'forty-seven'}>47</Typography>
            <Typography className={'brigade-title'}>бригада</Typography>
            <Typography className={'text'}>У квітні 2022 року Іван Шаламага, який на той момент займав посаду замкомбата у складі славетної
                30-ї бригади, та розвідник Валерій Маркус отримали пропозицію від ЗСУ очолити новостворений 47-й
                окремий батальйон. Валерій Маркус – як головний сержант, Іван Шаламага – у якості комбата. Вони
                поставили перед собою задачу створити не просто рядовий батальйон, а збудувати підрозділ на
                кардинально нових засадах: <b>повага, принцип безперервного навчання, внутрішня
                    культура.</b>
                <br />
                <br />
                Влітку 2022 року батальйон було розширено до 47 Штурмового полку, а з листопада 2022 – до 47 бригади.
            </Typography>

            <Box style={{display: 'flex', justifyContent: 'end', marginTop: '38px'}}>
                <Button className={'btn btn-gold'} style={{marginRight: '43px'}} component={RouterLink}
                        to={URLs.BRIGADE}>Детальніше</Button>
            </Box>
        </Box>
    </Container>)
}
