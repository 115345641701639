import Button from "@mui/material/Button";
import CopyToClipboard from "../../components/CopyToClipboard";
import {Link} from "@mui/material";


export function TableButton({children, icon, textToCopy, href}) {
    const image = <img src={icon} alt={children}/>;

    if (href) {
        return (<Link href={href}
                      className={'table-button'}
                      target={'_blank'}>
            {image}
            {children}
        </Link>);
    }

    return (
        <CopyToClipboard>{({copy}) => (
            <Button
                className={'table-button'}
                onClick={() => copy(textToCopy)}>
                {image}
                {children}
            </Button>
        )}
        </CopyToClipboard>
    );
}
