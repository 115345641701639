import * as React from 'react';
import './assets/styles/index.scss';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Brigade from "./pages/brigade/Brigade";
import Home from "./pages/home/Home";
import Layout from "./layouts";
import Requisites from "./pages/requisites/Requisites";
import Reports from "./pages/reports/Reports";
import Foundation from "./pages/foundation/Foundation";
import Sitemap from "./pages/sitemap/Sitemap";
import {URLs} from "./constants";
import PageNotFound from "./pages/PageNotFound";
import ScrollToTop from "./utils/ScrollToTop";
import RequisitesEn from "./pages/requisites/RequisitesEn";
import {CONTENT_ROUTES} from "./pages/content";

export const ROUTES = [
    {url: URLs.FOUNDATION, component: <Foundation/>},
    {url: URLs.BATTALION, component: <Navigate to={URLs.POLK}/>},
    {url: URLs.POLK, component: <Navigate to={URLs.BRIGADE}/>},
    {url: URLs.BRIGADE, component: <Brigade/>},
    {url: URLs.REQUISITES, component: <Requisites/>},
    {url: URLs.REPORTS, component: <Reports/>},
    {url: URLs.SITEMAP, component: <Sitemap/>},
];

export default function App() {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Routes>
                    <Route path="*" element={<PageNotFound/>}/>
                    <Route path={URLs.REQUISITES_EN} element={<RequisitesEn/>}/>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Home/>}/>
                        {ROUTES.map((route, key) => <Route key={key} path={route.url} element={route.component}/>)}
                    </Route>
                    <Route path={'content'} element={<Layout/>}>
                        {CONTENT_ROUTES
                            .map((route, key) => <Route key={key} path={route.url} element={route.component}/>)}
                    </Route>
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    );
}
