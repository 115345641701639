import * as React from "react";
import '../../assets/styles/Content.scss';
import {Box, Typography} from "@mui/material";

export default function Video5() {
    const videoUrl = '/content/video-5.MOV';

    return (
        <React.Fragment>
            <Box id={'content-box'}>
                <Box id={'video-card'}>
                    <video controls muted src={videoUrl}></video>

                    <Box className={'video-details'}>
                        <small className={'source'}>Джерело: офіційний акаунт 47 ОМБр <a
                            href={'https://t.me/brygada47/80'}>https://t.me/brygada47/80</a></small>
                        <small className={'date'}>14 березня 2024 року</small>
                    </Box>
                </Box>

                <Box className={'content-body'}>
                    <Typography>Чергова спроба штурмувати наші позиції «по сірому» – провалена.</Typography>

                    <Typography>Бійці 47 окремої механізованої бригади знищили три ворожі БТР. Так виглядає взаємодія
                        протитанкістів, артилеристів, роти ударних БпЛА та екіпажу Бредлі.</Typography>

                    <Typography>Також був ліквідований російський десант чисельністю 35 окупантів. І це ті підрозділи,
                        які після того, як наша 47-ма розмотала їх на молекули, вийшли на відновлення на 2 місяці та
                        знову повернулися. Згідно з тими документами, які в них знайшли, мобілізовані вони були 5
                        березня 2024-го року.
                    </Typography>

                    <Typography>9 днів їм пощастило прожити на нашій українській землі.</Typography>
                    <Typography>📍Авдіївський напрямок</Typography>
                </Box>
            </Box>

        </React.Fragment>
    )
}
