import {Box, Typography} from "@mui/material";

import brandNewBrigade from '../../../assets/images/about-brand-new.jpg';

export default function BrandNewBrigade() {
    return (
        <Box id={'brand-new-brigade'}>
            <Box className={'image'}>
                <img src={brandNewBrigade} alt={'Підрозділ на кардинально нових засадах'}/>
            </Box>
            <Box className={'text-wrapper'}>
                <Typography className={'subtitle'}>47 бригада</Typography>
                <Typography className={'title'} variant={'h2'}>
                    Підрозділ на кардинально<br/>нових засадах
                </Typography>
            </Box>
        </Box>
    )
}
