import * as React from "react";
import '../../assets/styles/Foundation.scss';
import {Box, Container, Grid, Typography} from "@mui/material";
import JoinTheVictory from "../brigade/sections/JoinTheVictory";

import foundation1 from '../../assets/images/foundation-1.jpg';
import foundation2 from '../../assets/images/foundation-2.jpg';
import foundation4 from '../../assets/images/foundation-4.jpg';
import {useRef} from "react";
import useOnScreen from "../../utils/useOnScreen";
import {Link} from "react-router-dom";
import {URLs} from "../../constants";
import Button from "@mui/material/Button";

export default function Foundation() {
    const createdRef = useRef();
    const isCreatedVisible = useOnScreen(createdRef);

    return (
        <React.Fragment>
            <Box id={'foundation-title'}>
                <Container>
                    <Box className={'vertical-divider fadeInDown'}/>
                    <Typography className={'fadeInDown support'}>підтримай 47 бригаду</Typography>

                    <Typography className={'fadeInDown'} variant={'h1'}>
                        Про благодійний фонд Markus Foundation
                    </Typography>

                    <Typography className={'fadeInDown main-directions'} style={{color: '#b58b5b'}}>
                        Основні напрями роботи фонду:
                    </Typography>

                    <Box className={'fadeInUp main-directions'}>
                        Матеріально-технічне забезпечення 47 бригади<br/>
                        Навчання та тренування із залученням інструкторів провідних армій світу<br/>
                        Підтримка поранених та сімей загиблих героїв
                    </Box>
                </Container>
            </Box>

            <Box id={'foundation-created'} ref={createdRef}>
                <Grid container>
                    <Grid item md={6} lg={5} className={isCreatedVisible ? 'fadeInLeft' : ''}>
                        <Box className={'image-placeholder'}>
                            <img src={foundation1} alt={'Фонд'} style={{maxWidth: '100%'}}/>
                        </Box>
                    </Grid>
                    <Grid item md={6} lg={7} id={'foundation-created-text'}
                          className={isCreatedVisible ? 'fadeInRight' : ''}>
                        <Typography variant={'h2'}>Фонд Markus Foundation створений керівництвом 47 бригади</Typography>
                        <Typography variant={'body1'} sx={{textAlign: 'left'}}>
                            Благодійний фонд Markus Foundation – неурядова організація, що займається забезпеченням,
                            розвитком та підтримкою 47 бригади. <br/><br/>
                            Підрозділ створений у травні 2022 року як 47 окремий штурмовий батальйон, а згодом – полк,
                            який в листопаді розширений до бригади, входить до складу Збройних Cил України.
                            <br/>
                            <br/>
                            Фонд Markus Foundation призначений спеціально для розв'язання питань постачання та
                            забезпечення підрозділу.
                            <br/>
                            <br/>
                            Засновником та головним ідеологом фонду є Валерій Маркус – Головний сержант 47 бригади,
                            ветеран російсько-української війни, письменник, автор книги «Сліди на дорозі».
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box id={'foundation-mission'}>
                <Box>
                    <img src={foundation2} alt={''} style={{maxWidth: '100%'}}/>
                </Box>
                <Grid container>
                    <Grid item md={6}>
                        <Box className={'box'}>
                            Місія фонду Markus Foundation:
                        </Box>
                    </Grid>
                    <Grid item md={6} className={'description'}>
                        <Typography variant={'h2'} className={'title'}>
                            Місія фонду Markus Foundation: забезпечення, допомога пораненим та сім'ям загиблих героїв.
                        </Typography>
                        <Typography variant={'body2'}>
                            Наше завдання – ефективно розвивати новостворений підрозділ, забезпечувати матеріальне та
                            технічне забезпечення, надавати допомогу пораненим бійцям та всебічно підтримувати сім'ї
                            загиблих героїв.
                            <br/>
                            <br/>
                            На жаль, держава не може в умовах тривалої війни забезпечити всі підрозділи якісною та
                            сучасною технікою та спорядженням у повному обсязі. Фонд Markus Foundation буде розв'язувати
                            питання 47 бригади там, де для Міноборони зробити це проблематично.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box id={'provision'}>
                <Box className={'image'}>
                    <img src={foundation4} alt={'Чим ми забезпечуємо 47 бригаду'}/>
                </Box>

                <Box className={'text'}>
                    <Typography variant={'h2'}>Чим ми забезпечуємо 47 бригаду</Typography>

                    <Typography variant={'body2'}>
                        На війні немає дрібниць. Кожна складова забезпечення воїна впливає на його ефективність. Йдеться
                        не про комфорт. Мета фонду – створення найкращих умов для досягнення перемоги.
                        <br/>
                        <br/>
                        Ми всі у неї віримо. Але вірити замало. Допомагають конкретні, осяжні речі: круті, як
                        тепловізори й бронежилети, відстанеміри чи метеостанції; і прості, як штани за погодою і берці
                        за розміром.
                        <br/>
                        <br/>
                        Армія надає багато необхідного, але не може подбати про все. Фонд Markus Foundation закуповує
                        саме те, що необхідно бійцям підрозділу для ефективного знищення ворога.
                    </Typography>
                </Box>
            </Box>

            <Box id={'foundation-reporting'}>
                <Container>
                    <Typography variant={'h2'}>Закупівлі фонду Markus Foundation</Typography>
                    <Typography variant={'body1'}>
                        Ми купуємо обладнання, медикаменти, військове екіпірування та техніку.
                        <br/>
                        Ви можете ознайомитися з переліком закупівель на окремій сторінці за посиланням
                    </Typography>
                    <Box>
                        <Button className={'btn btn-gold btn-lg text-uppercase'} component={Link} to={URLs.REPORTS}>
                            Переглянути закупівлі
                        </Button>
                    </Box>
                </Container>
            </Box>

            <JoinTheVictory/>
        </React.Fragment>
    )
}
