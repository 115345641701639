import * as React from "react";
import Slogan from "./sections/Slogan";
import HowBrigadeWasFormed from "./sections/HowBrigadeWasFormed";
import MeetingWithCommanderInChief from "./sections/MeetingWithCommanderInChief";
import Interviews from "./sections/Interviews";
import Mission from "./sections/Mission";
import BrandNewBrigade from "./sections/BrandNewBrigade";
import BrigadeValues from "./sections/BrigadeValues";
import SeparateAssaultRegiment from "./sections/SeparateAssaultRegiment";
// import ForSoldiers from "./sections/ForSoldiers";
import ForCivilians from "./sections/ForCivilians";
import JoinTheVictory from "./sections/JoinTheVictory";
import '../../assets/styles/Brigade.scss';
import {Box} from "@mui/material";

export default function Brigade() {
    return (
        <Box id={'brigade'}>
            <Slogan/>
            <HowBrigadeWasFormed/>
            <MeetingWithCommanderInChief/>
            <Interviews/>
            <Mission />
            <BrandNewBrigade/>
            <BrigadeValues />
            <SeparateAssaultRegiment />
            {/*<ForSoldiers/>*/}
            <ForCivilians/>
            <JoinTheVictory/>
        </Box>
    )
}
