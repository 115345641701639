import * as React from 'react';

import Button from "@mui/material/Button";
import {Divider, styled} from "@mui/material";

import logoIcon from '../assets/images/logo-icon.svg';
import {Link} from "react-router-dom";
import {THEME, URLs} from "../constants";

const StyledDivider = styled(Divider)({
    backgroundColor: '#fff',
    borderLeft: '1px solid #fff',
    height: '20px',
    margin: '0 5px'
})

export function LogoIcon() {
    return (
        <span style={{width: '27px', height: '20px', backgroundImage: `url(${logoIcon})`}}></span>
    );
}

export default function DonateButton({theme = THEME.LIGHT}) {
    return (<Button id={'donate-button'} className={theme} component={Link} to={URLs.REQUISITES}>
        <LogoIcon/>
        <StyledDivider/>
        Зробити внесок
    </Button>)
}
