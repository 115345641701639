import * as React from "react";
import {
    Box,
    Container, Divider, Link, styled,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";

import qr from '../../assets/images/qr-code.svg';
import copyIcon from '../../assets/images/copy-icon.png';
import paypalIcon from '../../assets/images/paypal-icon.png';

import '../../assets/styles/Requisites.scss';

import {TableButton} from "./index";
import SocialEn from "../../components/SocialEn";
import AppFooter from "../../components/AppFooter";
import AppHeader from "../../components/AppHeader";
import {useState} from "react";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const LiDashed = styled('li')({
    listStyleType: 'none',
    '&:before': {
        content: "'\\2014'",
        position: 'absolute',
        marginLeft: '-25px'
    }
})

export default function RequisitesEn() {
    const [showMore, setShowMore] = useState(false);

    return (
        <React.Fragment>
            <AppHeader showMenuButton={false} showDonateButton={false}/>
            <Container id={'requisites'}>
                <Box className={'vertical-divider'}></Box>
                <Typography className={'support'}>SUPPORT THE 47TH BRIGADE</Typography>

                <Typography variant={'h1'}>REQUISITES OF THE CHARITABLE FOUNDATION “MARKUS FOUNDATION”</Typography>

                <Box sx={{textAlign: 'left', my: '1rem'}}>
                    <Typography sx={{fontWeight: 'bold', fontSize: '13px'}}>
                        The Markus Foundation is a charitable foundation created to support and provide for the needs of
                        the
                        47th Brigade of the Armed Forces of Ukraine. The 47th Brigade is a new unit of the Armed Forces
                        of
                        Ukraine, which was formed in 2022.
                    </Typography>

                    <Typography sx={{mt: 1, fontSize: '13px'}}>Our main goals:</Typography>

                    <ol style={{paddingLeft: '1rem', marginTop: '.5rem', fontSize: '13px'}}>
                        <li>To liberate Ukraine from the enemy invasion.</li>
                        <li>To create preconditions for a global reforming of military units in the Armed Forces of
                            Ukraine.
                        </li>
                    </ol>
                </Box>

                <Box sx={{width: '100%', position: 'relative', py: '1rem'}}>
                    <Divider/>
                    <Link sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '125px',
                        userSelect: 'none',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: '3px',
                        bottom: 0,
                        background: '#fff',
                        padding: '0 10px',
                        margin: '0 auto',
                        color: '#000',
                        cursor: 'pointer',
                        fontSize: '13px',
                        textDecoration: 'none'
                    }}
                          onClick={() => setShowMore(!showMore)}>
                        {showMore ? <ExpandLess/> : <ExpandMore/>}
                        {showMore ? 'show less' : 'show more'}
                    </Link>
                </Box>

                {showMore && <Box sx={{textAlign: 'left'}}>
                    <Typography sx={{mt: 1, fontWeight: 'bold', fontSize: '13px'}}>
                        The founder and ideologist of the foundation is Valerii Markus – Master Sgt. of the 47th
                        Brigade,
                        veteran of the Russian-Ukrainian war, writer, author of the book “Footprints on the Road,” which
                        has
                        been published in many languages in different countries around the world.
                    </Typography>

                    <Typography sx={{mt: 1, fontSize: '13px'}}>
                        The Markus Foundation provides the 47th Brigade fighters with exactly what they need to expel
                        the enemy from our land effectively. We purchase medical supplies, equipment, machinery, etc.
                    </Typography>

                    <Typography sx={{mt: 1, fontWeight: 'bold', fontSize: '13px'}}>
                        You can support the 47th Brigade with your donation – funds transfer options:
                    </Typography>

                    <ul style={{paddingLeft: '2rem', marginTop: '.5rem', fontSize: '13px'}}>
                        <LiDashed>Payment card, Apple Pay, Google Pay,</LiDashed>
                        <LiDashed>PayPal,</LiDashed>
                        <LiDashed>International transfer to account in Ukrainian bank in UAH, USD, EUR, or
                            PLN,</LiDashed>
                        <LiDashed>In cryptocurrency: BTC, ETH, TRC20.</LiDashed>
                    </ul>

                    <Typography sx={{mt: 1, fontSize: '13px'}}>
                        You can also conveniently copy all the requisites for the donation – press the COPY button
                        below.
                    </Typography>

                    <Typography sx={{mt: 1, fontSize: '13px'}}>
                        When using the international transfer to account, please indicate the Purpose of payment
                        (payment
                        description) in accordance with your country's legislation on payments to charitable
                        organizations.
                        Check with your bank about the correct phrasing.
                    </Typography>

                    <Typography sx={{mt: 1, fontSize: '13px'}}>
                        Friends and partners! Thank you for supporting our 47th Brigade!
                    </Typography>
                </Box>}

                <Table className={'money-transfers'} sx={{mb: 0}}>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3}>PAYMENT CARD, APPLE PAY OR GOOGLE PAY</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}>
                                Please, follow the link to donate with payment card, Apple Pay or Google Pay: <Link
                                target={'_blank'}
                                href={'https://send.monobank.ua/jar/6eV2c6enbF'}>DONATE</Link>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table sx={{mb: 0}}>
                    <TableBody>
                        <TableRow>
                            <TableCell>PayPal:</TableCell>
                            <TableCell>vvs.vasiliev@gmail.com</TableCell>
                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'vvs.vasiliev@gmail.com'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow className={'no-border'}>
                            <TableCell></TableCell>
                            <TableCell>
                                <Typography>GO TO THE PAYPAL SITE FOR FUNDS TRANSFER</Typography>
                            </TableCell>
                            <TableCell>
                                <TableButton icon={paypalIcon}
                                             href={'https://www.paypal.com/donate/?hosted_button_id=TL6PF26ZZRMYG'}>Donate</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow className={'no-border'} id={'qr-code'}>
                            <TableCell></TableCell>
                            <TableCell>
                                <Typography>OR JUST POINT THE CAMERA<br/>
                                    OF YOUR SMARTPHONE TO THE QR CODE TO<br/>QUICKLY TRANSFER FUNDS
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <img src={qr} alt={'QR-code'} style={{maxWidth: '150px'}}/>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table className={'money-transfers'}>
                    <TableBody>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>CRYPTOCURRENCY</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>BTC:</TableCell>
                            <TableCell className={'normal-case'}>16yqRqu2y9WVhaByeZD4ZxBNYsA8TRb99W</TableCell>
                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'16yqRqu2y9WVhaByeZD4ZxBNYsA8TRb99W'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>ETH:</TableCell>
                            <TableCell className={'normal-case'}>0xce2497a387c31c57eb0257d1b00c37b4ee4c732f</TableCell>
                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'0xce2497a387c31c57eb0257d1b00c37b4ee4c732f'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>TRC20:</TableCell>
                            <TableCell className={'normal-case'}>TUCi52wocgdcehYf621hX1715ESYvGgbyy</TableCell>
                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'TUCi52wocgdcehYf621hX1715ESYvGgbyy'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Typography variant={'h5'} sx={{fontWeight: 'bold'}}>INTERNATIONAL TRANSFER TO ACCOUNT IN UKRAINIAN
                    BANK</Typography>

                <Table className={'money-transfers'}>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3}>(USD)</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Сompany Name:</TableCell>
                            <TableCell>CO"CHARITABLE FOUNDATION <br/> "MARCUS FOUNDATION"</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'CO"CHARITABLE FOUNDATION "MARCUS FOUNDATION"'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>IBAN Code:</TableCell>
                            <TableCell>UA483052990000026008006234325</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'UA483052990000026008006234325'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name of the bank:</TableCell>
                            <TableCell>JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO
                                STR., KYIV, 01001, UKRAINE</TableCell>
                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Bank SWIFT Code:</TableCell>
                            <TableCell>PBANUA2X</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'PBANUA2X'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Company address:</TableCell>
                            <TableCell>02141, Ukraine, Kiev, Mishugi
                                Oleksandra st., 10, office 405
                            </TableCell>
                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Correspondent banks Account in the correspondent bank:</TableCell>
                            <TableCell>001-1-000080</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'001-1-000080'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>SWIFT Code of the
                                correspondent bank:</TableCell>
                            <TableCell>CHASUS33</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'CHASUS33'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Correspondent bank:</TableCell>
                            <TableCell>JP Morgan Chase Bank, New York ,USA</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'JP Morgan Chase Bank, New York ,USA'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Account in the
                                correspondent bank:</TableCell>
                            <TableCell>890-0085-754</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'890-0085-754'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>SWIFT Code of the
                                correspondent bank:</TableCell>
                            <TableCell>IRVT US 3N</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'IRVT US 3N'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Correspondent bank:</TableCell>
                            <TableCell>The Bank of New York Mellon, New York, USA</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'The Bank of New York Mellon, New York, USA'}>Copy</TableButton>
                            </TableCell>
                        </TableRow></TableBody>
                </Table>
                <Table className={'money-transfers'}>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3}>(EUR)</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Сompany Name:</TableCell>
                            <TableCell>CO"CHARITABLE FOUNDATION <br/> "MARCUS FOUNDATION"</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'CO"CHARITABLE FOUNDATION "MARCUS FOUNDATION"'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>IBAN Code:</TableCell>
                            <TableCell>UA843052990000026002046220094</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'UA843052990000026002046220094'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name of the bank:</TableCell>
                            <TableCell>JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Bank SWIFT Code:</TableCell>
                            <TableCell>PBANUA2X</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'PBANUA2X'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Company address:</TableCell>
                            <TableCell>02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Correspondent banks Account in the correspondent bank:</TableCell>
                            <TableCell>400886700401</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'400886700401'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>SWIFT Code of the correspondent bank:</TableCell>
                            <TableCell>COBADEFF</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'COBADEFF'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Correspondent bank:</TableCell>
                            <TableCell>Commerzbank AG, Frankfurt am Main, Germany</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'Commerzbank AG, Frankfurt am Main, Germany'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Account in the correspondent bank:</TableCell>
                            <TableCell>6231605145</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'6231605145'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>SWIFT Code of the correspondent bank:</TableCell>
                            <TableCell>CHASDEFX</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'CHASDEFX'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Correspondent bank:</TableCell>
                            <TableCell>J.P.MORGAN AG, FRANKFURT AM MAIN, GERMANY</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'J.P.MORGAN AG, FRANKFURT AM MAIN, GERMANY'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table className={'money-transfers'}>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3}>(PLN)</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Сompany Name:</TableCell>
                            <TableCell>CO"CHARITABLE FOUNDATION <br/> "MARCUS FOUNDATION"</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'CO"CHARITABLE FOUNDATION "MARCUS FOUNDATION"'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>IBAN Code:</TableCell>
                            <TableCell>UA463052990000026002046234552</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'UA463052990000026002046234552'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name of the bank:</TableCell>
                            <TableCell>JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Bank SWIFT Code:</TableCell>
                            <TableCell>PBANUA2X</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'PBANUA2X'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Company address:</TableCell>
                            <TableCell>02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'02141, Ukraine, Kiev, Mishugi Oleksandra st., 10, office 405'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Correspondent banks Account in the correspondent bank:</TableCell>
                            <TableCell>PL13124000013140533111120301</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'PL13124000013140533111120301'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>SWIFT Code of the correspondent bank:</TableCell>
                            <TableCell>PKOPPLPW</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon} textToCopy={'PKOPPLPW'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Correspondent bank:</TableCell>
                            <TableCell>Bank Pekao/Grupa Pekao S.A., Warsaw, Poland</TableCell>

                            <TableCell>
                                <TableButton icon={copyIcon}
                                             textToCopy={'Bank Pekao/Grupa Pekao S.A., Warsaw, Poland'}>Copy</TableButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Container>

            <SocialEn/>

            <AppFooter/>
        </React.Fragment>
    )
}
