export enum THEME {
    LIGHT = 'light',
    DARK = 'dark'
}

export const LANDSCAPE_MEDIA = '(max-height: 500px) and (min-width: 600px) and (max-width:1000px)';

export enum URLs {
    MAIN = '/',
    HOME = 'home',
    FOUNDATION = '/blahodiynyy-fond-markus-foundation',
    BATTALION = '/47-okremyy-batalyon',
    POLK = '/47-shturmovyy-polk',
    BRIGADE = '/47-bryhada',
    REQUISITES = '/rekvizyty-blahodiynoho-fondu-markus-foundation',
    REQUISITES_EN = '/support-47-brigade-markus-foundation',
    REPORTS = '/zakupivli-blahodiynoho-fondu-markus-foundation',
    SITEMAP = '/mapa-saytu-blahodiynoho-fondu-markus-foundation',
}
